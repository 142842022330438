import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useRestaurants } from '../../Context/APIcontext.tsx';
import backbtn from '../../assets/backbutton.png'
import Bottle from '../../assets/bottle.png';
import './RestaurantGames.css';
import Bootle from '../Games/Bootle';
import QuizApp from '../Games/QuizApp';
import Vector14 from '../../assets/Vector14.png';
import Bottlesecond from '../../assets/bottle2.png'
import CardOne from '../../assets/cardOne.png'
import CardTwo from '../../assets/cardTwo.png'

Modal.setAppElement('#root');

const RestaurantGames = () => {
  const [isBootleModalOpen, setBootleModalOpen] = useState(false);
  const [isQuizAppModalOpen, setQuizAppModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    if (menuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen]);

  const openBootleModal = () => {
    setBootleModalOpen(true);
  };

  const closeBootleModal = () => {
    setBootleModalOpen(false);
  };

  const openQuizAppModal = () => {
    setQuizAppModalOpen(true);
  };

  const closeQuizAppModal = () => {
    setQuizAppModalOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className='games'>
      <header>
        <div className='header-content'>
          <div className='left-item'>
            <Link to={`/`}><img src={backbtn} alt="" /></Link>
          </div>

          <div className='right-item'>

          </div>
        </div>
      </header>

      <div className='title_menu'>
        <h1>Игры</h1>
      </div>

      <div className='games-title'>
        <p>
          “Мы разработали (и продолжаем разрабатывать) игры, чтобы сделать ваши посещения в любимое заведение ещё более интересным и увлекательным.
        </p>
        <span>
          Играйте с друзьями, играйте вдвоём! Играйте пока ждёте заказ!”
          </span>
      </div>

      <div className='restaurant-games'>
        <div className='card_menu_game' onClick={openBootleModal}>
          <img src={Bottlesecond} alt="" />
          <p>Игра "Бутылочка"</p>
        </div>


        <div className='card_menu_game' onClick={openQuizAppModal}>
          <img src={CardTwo} alt="" />
          <p>Игра “Викторина”</p>

        </div>

        <div className='card_menu_game' onClick={openQuizAppModal}>
          <img src={CardOne} alt="" />
          <p>Игра “Крестики - нолики”</p>

        </div>


      </div>
      <Modal
        isOpen={isBootleModalOpen}
        onRequestClose={closeBootleModal}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-iframe">
          <Bootle />
        </div>
      </Modal>
      <Modal
        isOpen={isQuizAppModalOpen}
        onRequestClose={closeQuizAppModal}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-iframe">
          <QuizApp />
        </div>
      </Modal>

      <Link style={{ textDecoration: "none" }} to={`/`}>
        <div className='btn-backto-page'>
          Назад
        </div>
      </Link>

    </div>
  );
}

export default RestaurantGames;



