import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRestaurants } from '../../Context/APIcontext.tsx';
import backbtn from '../../assets/backbutton.png'

const Promotions = () => {
  const {  getFetchPromotions, promotions } = useRestaurants();

  useEffect(() => {
    window.scrollTo(0, 0);
  getFetchPromotions()
  },
  []);

  return (
    <div className="promotions">
      <header>
        <div className='header-content'>
          <div className='left-item'>
            <Link to={`/`}><img src={backbtn} alt="" /></Link>
          </div>

          <div className='right-item'>
     
          </div>
        </div>
      </header>

      <div className='title_menu'>
        <h1>Акции и ивенты</h1>
      </div>

      <div className='promotions-cards'>
       
        {promotions && promotions.map((promotion, index) => (
          <div key={index} className='promotion-card'>
            <img src={promotion.image} alt="Discount" className='promotion-image' />

            <p className='promotion-text'>{promotion.description}</p>
          </div>
        ))}
      

      
      </div>
      <Link style={{ textDecoration: "none" }} to={`/`}>
        <div className='btn-backto-page'>
          Назад
        </div>
      </Link>

    </div>
  );
}

export default Promotions;
