import React from 'react'
import './Footer.css'
const Footer = () => {
  return (
    <div className='footer'>
        <span>Политика конфиденциальности | Договор публичной оферты</span>
        <span>2024 г. "Eat&Play” онлайн-сервис. Все права защищены</span>
    </div>
  )
}

export default Footer