import  axios  from 'axios'
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const RestaurantsContext = createContext();

export const RestaurantsProvider = ({ children }) => {
  const [restaurants, setRestaurants] = useState([]);
  const [restaurant, setRestaurant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [restaurantsCache, setRestaurantsCache] = useState({});
  const [preferences, setPreferences] = useState([]);
  const [cities, setCities] = useState([])
  const [promotions, setPromotions] = useState([])

  useEffect(() => {
    const fetchRestaurants = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch('https://back.eatplay.kz/api/restaurants');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRestaurantsCache(prevCache => ({ ...prevCache, all: data }));
        setRestaurants(data);
      } catch (error) {
        setError(error.message || 'Error fetching restaurants');
      } finally {
        setLoading(false);
      }
    };

    fetchRestaurants();
  }, []);

  const getFetchPreferences = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('https://back.eatplay.kz/api/preferences');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setPreferences(data);
    } catch (error) {
      setError(error.message || 'Error fetching preferences');
    } finally {
      setLoading(false);
    }
  }, []);

  const getFetchDataRestaurant = async (id:String) => {
    setLoading(true);
    setError(null);
    try {
      const cachedData = restaurantsCache[id];
      if (cachedData) {
        setRestaurant(cachedData);
      } else {
        const response = await fetch(`https://back.eatplay.kz/api/restaurants/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setRestaurantsCache(prevCache => ({ ...prevCache, [id]: data }));
        setRestaurant(data);
      }
    } catch (error) {
      setError(error.message || 'Error fetching restaurant details');
    } finally {
      setLoading(false);
    }
  };

  const getFetchCities = async () => {
    try {
      const res = await axios.get('https://back.eatplay.kz/api/cities');
      setCities(res.data); 
    } catch (error) {
      console.error('Ошибка при загрузке городов:', error);
      setCities([]); 
    }
  };

  const getFetchPromotions = async () => {
    try {
      const res = await axios.get('https://back.eatplay.kz/api/promotions');
      setPromotions(res.data); 
    } catch (error) {
      console.error('Ошибка при загрузке городов:', error);
      setCities([]); 
    }
  };

  return (
    <RestaurantsContext.Provider value={{ restaurants, restaurant, loading, error, getFetchDataRestaurant,setPromotions, promotions , preferences, getFetchPreferences, getFetchCities, getFetchPromotions,setCities,cities }}>
      {children}
    </RestaurantsContext.Provider>
  );
};

export const useRestaurants = () => {
  return useContext(RestaurantsContext);
};
