import React from 'react'
import lupa from '../../assets/lupa.png'
import './Main.css'
import { Link } from 'react-router-dom'
import Institution from '../Institution'
import RestaurantGames from '../RestaurantGames'
import phone from '../../assets/phone1.png';
import promotions from '../../assets/iconpromotions.png'

const Main = () => {
    return (

        <div className='App'>
            <div className='container_main'>

                <div className='header_banner'>
                    <div className='banner_titles'>
                        <h1>EAT&PLAY</h1>
                        <p>Сервис по поиску заведений <span>подходящего под ваш запрос<img src={lupa} alt="" width={14} /> </span></p>
                    </div>

                </div>
                <Link to={`/games`}><div className='item_block_game'><span>Игры</span><img src={phone} alt="phone" /></div></Link>
                <Link to={`/promotions`}><div className='item_block_game'><span>Акции и ивенты</span><img src={promotions} alt="phone" /></div></Link>

                <Institution />
            </div>
        </div>
    )
}

export default Main