import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRestaurants } from '../../Context/APIcontext.tsx';
import './institution.css';
import Filter from '../../assets/filter.png';
import Navigation from '../../assets/navigation.png'

const Institution = () => {
  const { restaurants, preferences, getFetchPreferences, cities = [], getFetchCities } = useRestaurants();

  const [selectedPreferences, setSelectedPreferences] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterPreferenceOpen, setFilterPreferenceOpen] = useState(false);

  useEffect(() => {
    getFetchPreferences();
    getFetchCities();
    window.scrollTo(0, 0);
  }, []);

  const handlePreferenceChange = (event) => {
    const { value, checked } = event.target;
    const preferenceId = Number(value); 
    if (checked) {
      setSelectedPreferences([...selectedPreferences, preferenceId]);
    } else {
      setSelectedPreferences(selectedPreferences.filter(pref => pref !== preferenceId));
    }
  };

  const handleCityChange = (event) => { 
    const { value, checked } = event.target;
    const cityId = Number(value);

    if (checked) { 
      setSelectedCity([...selectedCity, cityId]);
    } else { 
      setSelectedCity(selectedCity.filter(city => city !== cityId));
    }
  };

  const handleSearchChange = (event) => setSearchTerm(event.target.value.toLowerCase());

  const openFilterPreference = () => setFilterPreferenceOpen(!filterPreferenceOpen);

  const filteredRestaurants = restaurants.filter(restaurant => {
    const matchesPreferences = selectedPreferences.length === 0 || selectedPreferences.every(prefId =>
      restaurant.preferences.some(p => p.id === prefId)
    );
    const matchesCity = selectedCity.length === 0 || selectedCity.includes(restaurant.city_id); 
    const matchesSearchTerm = restaurant.title.toLowerCase().includes(searchTerm);
    return matchesPreferences && matchesCity && matchesSearchTerm;
  });

  return (
    <div className='main_block_institutions'>
      <div className='filter_preference_title'>
        <span className='filter_preference_container'>
          Выбор заведений <img onClick={openFilterPreference} src={Filter} alt="" />
        </span>
      </div>
      <div className={`${filterPreferenceOpen ? "filter_preference" : "hidden"}`}>
        <p>Предпочтения</p>
        {preferences.map(preference => (
          <div key={preference.id}>
            <input
              type='checkbox'
              value={preference.id}
              id={preference.id}
              onChange={handlePreferenceChange}
            />
            <label htmlFor={preference.id}>{preference.preference_name}</label>
          </div>
        ))}

        <div className='cities_items'>        
        <p>Город</p>
        <img src = {Navigation} alt="" />
        </div>
        {cities.map(city => (
          <div key={city.id}>
            <input
              type="checkbox"
              value={city.id}
              id={city.id}
              onChange={handleCityChange}
            />
            <label htmlFor={city.id}>{city.name}</label>
          </div>
        ))}

      </div>
      <input
        type='text'
        placeholder='Поиск ресторана'
        value={searchTerm}
        onChange={handleSearchChange}
        className='search_input'
      />
      <div className='restaurant_card_item'>
        {filteredRestaurants.map(restaurant => (
          <Link to={`/restaurant/${restaurant.id}`} key={restaurant.id} className='institution_card'>
            <div className='image_container'>
              <img src={restaurant.banner} alt={restaurant.title} className='institution_image' />
              <h1 className='title_restaurant'>{restaurant.title}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Institution;
